import React from 'react'

const VerticalRoofIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="121.334" height="26.417" viewBox="0 0 121.334 26.417">
	  <defs>
	    <clipPath id="clip-path">
	      <path id="Path_15456" data-name="Path 15456" d="M301.17.4l50.94,2.57,38.408,18.86-28.671,4.184L303.788,1.058Z" transform="translate(-301.17 -0.4)" fill="none"/>
	    </clipPath>
	  </defs>
	  <g id="Group_2352" data-name="Group 2352" transform="translate(-632 -204.986)">
	    <g id="Group_2347" data-name="Group 2347" transform="translate(632 205)">
	      <path id="Path_15443" data-name="Path 15443" d="M317.2,7.353l-9-3.985L301.837.379s-.892-.907-2.4.139C295.512,2.83,269.91,20.345,269.91,20.345h29.524l49.221.65Z" transform="translate(-269.91 -0.014)" fill={props.fill}/>
	      <path id="Path_15444" data-name="Path 15444" d="M301.17,12.17l50.94,2.561L390.518,33.6l-28.671,4.175L303.788,12.828Z" transform="translate(-270.518 -11.785)" fill="#fff"/>
	      <path id="Path_15445" data-name="Path 15445" d="M301.17,12.17l50.94,2.561L390.518,33.6l-28.671,4.175L303.788,12.828Z" transform="translate(-270.518 -11.785)" fill="none" stroke="#686c79" strokeMiterlimit="10" strokeWidth="0.8"/>
	      <g id="Group_2346" data-name="Group 2346" transform="translate(30.652 0.385)" clipPath="url(#clip-path)">
	        <path id="Path_15446" data-name="Path 15446" d="M279.44,5.407l7.913-.737,89.681,39.992L371.6,45.937Z" transform="translate(-300.747 -12.142)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15447" data-name="Path 15447" d="M287.74,4.837l7.619-.707,87.279,39.424L377.372,44.8Z" transform="translate(-300.909 -12.14)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15448" data-name="Path 15448" d="M295.74,4.287l7.344-.677,84.975,38.866-5.089,1.206Z" transform="translate(-301.064 -12.138)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15449" data-name="Path 15449" d="M303.45,3.768l7.089-.668,82.778,38.338-4.932,1.176Z" transform="translate(-301.214 -12.136)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15450" data-name="Path 15450" d="M310.89,3.258l6.844-.638,80.69,37.81-4.785,1.136Z" transform="translate(-301.359 -12.135)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15451" data-name="Path 15451" d="M318.08,2.768l6.609-.628,78.68,37.312-4.638,1.106Z" transform="translate(-301.499 -12.133)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15452" data-name="Path 15452" d="M325.03,2.3l6.383-.608L408.171,38.5l-4.5,1.076Z" transform="translate(-301.634 -12.131)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15453" data-name="Path 15453" d="M331.74,1.838l6.177-.588,74.914,36.335-4.373,1.046Z" transform="translate(-301.764 -12.13)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15454" data-name="Path 15454" d="M338.517-10.4l2.108-.2a27.667,27.667,0,0,1,6.481,1.076l69.825,34.233c.284.139.324.239.118.289h0c-1.128.269-7.844-1-9.394-1.7L338.468-10.261C338.321-10.331,338.341-10.381,338.517-10.4Z" transform="translate(-301.893 -0.359)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15455" data-name="Path 15455" d="M344.52.968,350.305.41l71.453,35.409-4.128.986Z" transform="translate(-302.013 -12.127)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	      </g>
	    </g>
	    <path id="Path_15457" data-name="Path 15457" d="M0,0,76.246.136,79.137,1.2,0,1Z" transform="translate(632 224.926)" fill="#e0e1e4"/>
	  </g>
	</svg>
)

export default VerticalRoofIcon