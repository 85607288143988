import React from 'react'

const FrameRoofIcon = (props)=> (
	<svg xmlns="http://www.w3.org/2000/svg" width="121.335" height="26.417" viewBox="0 0 121.335 26.417">
	  <defs>
	    <clipPath id="clipPath">
	      <path id="Path_15442" data-name="Path 15442" d="M161.97.4l50.94,2.57,38.418,18.86-28.671,4.184L164.6,1.058Z" transform="translate(-161.97 -0.4)" fill="none"/>
	    </clipPath>
	  </defs>
	  <g id="Group_2351" data-name="Group 2351" transform="translate(-841 -204.986)">
	    <g id="Group_2349" data-name="Group 2349" transform="translate(841 205)">
	      <path id="Path_15429" data-name="Path 15429" d="M178,7.353l-8.992-3.985L162.637.379s-.882-.907-2.393.139C156.322,2.83,130.72,20.345,130.72,20.345h77.66Z" transform="translate(-130.72 -0.014)" fill={props.fill}/>
	      <path id="Path_15430" data-name="Path 15430" d="M161.97,12.17l50.94,2.561L251.328,33.6l-28.671,4.175L164.6,12.828Z" transform="translate(-131.328 -11.785)" fill="#fff"/>
	      <path id="Path_15431" data-name="Path 15431" d="M161.97,12.17l50.94,2.561L251.328,33.6l-28.671,4.175L164.6,12.828Z" transform="translate(-131.328 -11.785)" fill="none" stroke="#686c79" strokeMiterlimit="10" strokeWidth="0.8"/>
	      <g id="Group_2345" data-name="Group 2345" transform="translate(30.642 0.385)" clipPath="url(#clip-path)">
	        <path id="Path_15432" data-name="Path 15432" d="M230.713,45.409l-7.933-3.089L270.2,34.37l4.54,2.152Z" transform="translate(-163.153 -12.252)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15433" data-name="Path 15433" d="M223,42.322l-8.276-3.228,50.832-6.934,4.667,2.222Z" transform="translate(-162.996 -12.244)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15434" data-name="Path 15434" d="M214.949,39.1l-8.639-3.377,54.46-5.828,4.795,2.282Z" transform="translate(-162.832 -12.235)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15435" data-name="Path 15435" d="M206.561,35.74l-9.031-3.527,58.314-4.663,4.942,2.351Z" transform="translate(-162.662 -12.227)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15436" data-name="Path 15436" d="M197.8,32.234l-9.443-3.7,62.422-3.387,5.089,2.421Z" transform="translate(-162.483 -12.218)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15437" data-name="Path 15437" d="M188.654,28.568,178.77,24.7l66.805-2.022,5.236,2.491Z" transform="translate(-162.297 -12.209)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15438" data-name="Path 15438" d="M179.084,24.743,168.72,20.678,240.2,20.14l5.4,2.561Z" transform="translate(-162.101 -12.199)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15439" data-name="Path 15439" d="M169.065,20.724,158.2,16.46l76.473,1.056,5.56,2.64Z" transform="translate(-161.897 -12.186)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15440" data-name="Path 15440" d="M158.146,4.555,154.224,3.01c-1.912-.747-3.657-2.63-2.393-2.58l76.9,2.561a2,2,0,0,1,.765.219h0c1.549.737,2.471,2.481,1.324,2.471l-71.826-1a2.5,2.5,0,0,1-.843-.13Z" transform="translate(-161.764 -0.4)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15441" data-name="Path 15441" d="M147.562,12.1l-12-4.713,87.534,4.633,5.913,2.82Z" transform="translate(-161.456 -12.152)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	      </g>
	    </g>
	    <path id="Path_15458" data-name="Path 15458" d="M0,0,76.246.136,79.137,1.2,0,1Z" transform="translate(841 224.926)" fill={props.fill}/>
	  </g>
	</svg>
)

export default FrameRoofIcon